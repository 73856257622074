* {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-size: 16px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #000;
  color: #fff;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

img {
  width: 100%;
  height: auto;
}

.App {
  height: 100%;
  width: 100%;
  min-height: 100vh;
  position: relative;
}

.header,
header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 100;
  padding: 20px 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  h1 {
    margin: 0;
  }

  h1 .logo {
    width: 15vw;
  }
}

nav ul {
  display: flex;
  align-items: center;
  font-size: 1.2rem;
  li {
    list-style: none;
    margin-right: 15px;
  }

  li:last-child {
    margin-right: 0;
  }
}

a {
  text-decoration: none;
  color: #f1f1f1;
  text-transform: lowercase;

  &:hover {
    text-decoration: underline;
  }
}

button,
.button {
  background: transparent;
  font-size: 1rem;
  font-weight: 600;
  border: 3px solid #fff;
  padding: 0.75rem 1.5rem;
  text-transform: lowercase;
  transition: all 1s ease-in-out;

  &:hover {
    background: #fff;
    color: #000;
  }
}

.home {
  display: flex;
  position: relative;
  .left {
    width: 50%;
    height: 100%;
    img {
      width: 100%;
      height: auto;
    }
  }

  .right {
    position: -webkit-sticky;
    position: sticky;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    width: 50%;
    top: 0;
  }
}

footer {
  font-size: 0.8rem;
  text-align: center;
  padding: 8px 0;
}

.social {
  font-size: 1.5rem;
  display: flex;
  flex-direction: column;
  position: fixed;
  z-index: 100;
  right: 18px;
  top: 50%;
  a {
    margin-bottom: 8px;
  }
}

.about {
  height: 100vh;
  padding-top: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  .content-wrap {
    font-size: 18px;
    text-align: center;
    padding: 60px;
    max-width: 920px;
    line-height: 1.4;
  }
}

.epk {
  padding-top: 20vh;
  .content-wrap {
    background: white;
    padding: 40px;
    text-align: left;
    width: 80vw;
    max-width: 960px;
    margin: 0 auto;
    display: grid;
  }
  a {
    color: rgb(124, 124, 124);
    text-transform: uppercase;
    font-size: 13px;
    margin-right: 10px;
  }
  a:hover {
    color: rgb(85, 85, 85);
  }
}

.item {
  margin: 20px;
  img {
    width: 100%;
    height: auto;
  }
}

.press {
  min-height: calc(100vh - 31px);
  padding-top: clamp(100px, 10vh, 100px);
  display: flex;
  font-size: 18px;
  line-height: 1.4;
  .left {
    width: 40%;
    height: calc(100vh - 131px);
    background: url(imgs/tall1.jpg);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    z-index: 1;
  }
  .right {
    width: 60%;
    height: 100%;
    // min-height: calc(100vh - 131px);
    padding: 60px;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-between;

    p {
      padding: 20px;
    }
  }

  .album {
    display: flex;
    width: 100%;
  }
  .item {
    img {
      width: 100%;
      max-width: 400px;
      height: auto;
    }
  }

  .item-2 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 10px;
    width: 80%;
    text-align: center;
  }
}

@media (max-width: 940px) {
  .App {
    height: 100vh;
  }
  .header {
    mix-blend-mode: difference;
    h1 .logo {
      width: 30vw;
    }
  }
  .home {
    display: inline-block;

    .left {
      width: 100%;
      height: 100vh;
      max-height: 100vh;
      img {
        object-fit: cover;
        object-position: center;
        width: 100%;
        height: 100vh;
      }
    }
    .right {
      position: fixed;
      top: 0;
      width: 100%;
      max-height: 100vh;
      mix-blend-mode: difference;
    }
  }
  .press {
    display: inline-block;
    padding-top: 0;
    .left,
    .right {
      width: 100%;
      p {
        padding: 10px;
      }
    }

    .album {
      display: inline-block;
      width: 100%;
    }
    .item {
      img {
        width: 100%;
        height: auto;
      }
    }

    .item-2 {
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 100%;
    }
  }
}

.flex {
  display: flex;
}

.contact {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}
